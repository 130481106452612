<template>
    <div class="relative flex flex-col bg-white rounded-3xl px-20 pt-12 h-auto pb-20 max-w-screen-md">
        <p class="text-3xl font-extrabold">Get in touch:</p>
        <p class="mt-5">If you do not know who to contact for your specific requery, please leave us a message and we will make sure the correct person
            gets back to you as soon as possible.</p>
        <div class="flex items-center justify-center md:justify-end">
            <NuxtLink to="/contact" class="absolute bottom-5 ">
                <button type="button"
                    class="text-white bg-green-900 hover:bg-green-950 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Contact us >></button>
            </NuxtLink>
        </div>
    </div>
</template>